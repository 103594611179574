import React from "react";
import styled from "styled-components";
import { convertAmountToRawNumber, convertStringToHex } from "./helpers/bignumber";
import NumberFormat from 'react-number-format';
import {   apiGetAccountNonce , apiGetGasPrices } from "./helpers/api";
// import {
//   sanitizeHex,
//   verifySignature,
//   hashTypedDataMessage,
//   hashMessage,
// } from "./helpers/utilities";
import {
  sanitizeHex 
} from "./helpers/utilities";
// import { apiGetAccountAssets, , apiGetAccountNonce } from "./helpers/api";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import Web3Modal from "web3modal"
import { IInternalEvent } from "@walletconnect/types";
import {  ethers,  providers } from 'ethers';
import contractAddress from  "./contracts/contract-address.json";
import tokenAddress from "./contracts/token-address.json";
import * as TokenSale from './contracts/TokenSale.json';
import * as TiggerPup from './contracts/TiggerPup.json';
import * as TiggerPupVIP from './contracts/TiggerPupVIP.json';
import {  getChainData } from "./helpers/utilities";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { TailSpin } from  'react-loader-spinner'
import Button from "./components/Button";
import Column from "./components/Column";
import Wrapper from "./components/Wrapper";
import Modal from "./components/Modal";
import Header from "./components/Header";
import Loader from "./components/Loader";
import { fonts } from "./styles";
import { IAssetData } from "./helpers/types";
import Banner from "./components/Banner";
import AccountAssets from "./components/AccountAssets";
import { apiGetAccountAssets   } from "./helpers/api";
 

const SLayout = styled.div`
  position: relative;
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
  text-align: center;
`;

const SContent = styled(Wrapper as any)`
  width: 100%;
  height: 100%;
  padding: 0 16px;
  background-color: #ef7129; 
`;

const SLanding = styled(Column as any)`
  height: 300px;
`;

const SButtonContainer = styled(Column as any)`
  width: 250px;
  margin: 50px 0;
`;

const SConnectButton = styled(Button as any)`
  border-radius: 8px;
  font-size: ${fonts.size.medium};
  height: 44px;
  width: 100%;
  margin: 12px 0;
   background:color: #8e7e76;
    color: #ef7129;    
`;

const SContainer = styled.div`
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
`;

const SModalContainer = styled.div`
  width: 100%;
  position: relative;
  word-wrap: break-word;
`;

const SModalTitle = styled.div`
  margin: 1em 0;
  font-size: 20px;
  font-weight: 700;
`;

const SModalParagraph = styled.p`
  margin-top: 30px;
`;

// @ts-ignore
const SBalances = styled(SLanding as any)`
  height: 100%;
  & h3 {
    padding-top: 30px;
  }
`;

const STable = styled(SContainer as any)`
  flex-direction: column;
  text-align: left;
`;

const SRow = styled.div`
  width: 100%;
  display: flex;
  margin: 6px 0;
`;

const SKey = styled.div`
  width: 30%;
  font-weight: 700;
`;

const SValue = styled.div`
  width: 70%;
  font-family: monospace;
`;

const STestButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #ef7129;
`;

const STestButton = styled(Button as any)`
  border-radius: 8px;
  font-size: ${fonts.size.medium};
  height: 44px;
  width: 300px;
  
  margin: 12px;
  background-color: #8e7e76!;
    color: #ef7129;  

`;

 const SInput = styled.input`
  background: #eeeeee;
  color: #000;
  cursor: pointer;
  margin-bottom: 0;
  width: 300px;
  border-radius: 5px;
  height: 35px;
  margin: 4px;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  &:active {
    background-color: #f1ac15;
  }
 `


interface IAppState {
  connector: WalletConnect | null;
  fetching: boolean;
  connected: boolean;
  chainId: number;
  showModal: boolean;
  pendingRequest: boolean;
  uri: string;
  accounts: string[];
  address: string;
  result: any | null;
  assets: IAssetData[];
  saleDuration:  number;
  tokenPrice:  number;
  saleAvailable:  number;
  minPurchase: number;
  maxPurchase: number;
  isSaleActive: boolean;
  isAdmin: boolean;
  investment: number;
  tokensSold: number;
  saleStart: number;
  saleEnd: number;
  saleStartAvailable: number;
  saleCurrentAvailable: number;
  tokenBalanceSold: number;
  salesDetails: array;
  salesMaster: array;
  inProcessOfCalling: bool;
   tokenContracts: any;
   tokenArray: array;
   saleContract: any;
   tokenContract: any;
    signer: any;
    provider : any;
    tokenCurrency: string;
    userBalance: number;
    loading: bool;
    salesContractBalance: number;
    saleready: bool;
    purchaseqty:   number;
    investmentmsg: string;
    listener8: any;
    wcmode: bool;
    walletAddress: string;
    tiggerpupAddress: string;
    walletBalance: number;
    tiggerpupVIPAddress: string;
    viptokenContract: any;
    VipUserBalance: number;
    mywalletBalance: number;
}
 

  
const INITIAL_STATE: IAppState = {
  connector: null,
  fetching: false,
  connected: false,
  chainId: 56,
  showModal: false,
  pendingRequest: false,
  uri: "",
  accounts: [],
  address: "",
  result: null,
  assets: [] ,
   saleDuration:  0,
  tokenPrice:  0,
  saleAvailable:  0,
  minPurchase: 0,
  maxPurchase: 0,
  isSaleActive: false,
  isAdmin: false,
  investment: 0,
  tokensSold: 0,
  saleStart: 0,
  saleEnd: 0,
  saleStartAvailable: 0,
  saleCurrentAvailable: 0,
  tokenBalanceSold: 0,
  salesDetails: [],
  salesMaster: [],
  inProcessOfCalling: false,
   tokenContracts: {},
   tokenArray: [],
   saleContract: {},
   tokenContract: {},
    signer: {},
    provider : {},
    tokenCurrency: "TIGGERPUP",
    userBalance: 0,
    loading: false,
    salesContractBalance: 0,
    saleready: false,
    purchaseqty: 0,
    investmentmsg: "",
    listener8: {},
    wcmode: true,
    walletAddress: "",
    tiggerpupAddress: "",
    walletBalance: 0,
    tiggerpupVIPAddress: "0xD4CD42629372414613fEcd216B18AB3969e04C3f" ,
    viptokenContract: {},
    VipUserBalance: 0,
    mywalletBalance: 0
};


class App extends React.Component<any, any> {
  public state: IAppState = {
    ...INITIAL_STATE,
  };
    
    

const convertduration = ( duration) => {
  return new Date(duration * 1000).toISOString().substr(11, 8);
}
const converttimestamp = ( timestamp) => {
  var date1 = new Date((timestamp - (3 * 60 * 60)) * 1000);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = date1.getFullYear();
  var month = months[date1.getMonth()];
  var date = date1.getDate();
  var hour = date1.getHours();
  var min = date1.getMinutes();
  var sec = date1.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
  return time;
}
 

  const checkisAdmin = async (e) => {
     let addminadd = await this.state.saleContract.owner({from: this.state.address});
     return  this.state.address.toLowerCase() == addminadd.toLowerCase();
  }
 

 
 
  const checkTokenStatus = async (e) => {
   let tokentotalSupply = await this.state.tokenContracts[this.state.tokensItem.tickertext].totalSupply();
   let tokenbalanceAvailable = await this.state.saleContract.tokenbalanceAvailable({from: this.state.address});
   return ([tokentotalSupply,tokenbalanceAvailable])
  }

 const getsalesmaster = async (e) => {
      let salesmaster = await this.state.saleContract.fetchSaleMaster({from: this.state.address});
      this.setState({salesMaster: (salesmaster)});
   }

   const getsalesdetails = async (e) => {
      let salesmaster = await this.state.saleContract.fetchSaleMaster({from: this.state.address});
      let salesdetails = [];
      // let salesdetails2 = [];
      if (salesmaster.length > 0) {
        let salesnumber = salesmaster.length - 1;
       
        let sale1 = salesmaster[salesnumber].saletime.toString();
      
        salesdetails = await this.state.saleContract.fetchSaleDetail(sale1, {from: this.state.address});
        // console.log("salesdetails",salesdetails )
        
        if (salesdetails.length > 0) {
          this.setState({salesDetails: (salesdetails)});
        }
        
      }
      //  if (salesmaster.length > 1) {
      //   let salesnumber = salesmaster.length - 2;
       
      //   let sale1 = salesmaster[salesnumber].saletime.toString();
      
      //   salesdetails2 = await this.state.saleContract.fetchSaleDetail(sale1, {from: this.state.address});
      //   // console.log("salesdetails",salesdetails )
        
      //   if (salesdetails2.length > 0) {
      //     this.setState({salesDetails: [this.state.salesdetails, ...salesdetails2]});
      //   }
        
      // }
    
 
   }
     
  
 
  const checksaleactive = async (e) => {
     let issaleactive = await this.state.saleContract.saleend({from: this.state.address});
     return issaleactive.toString() !== "0";
   }

  const checkSaleStatus = async (e) => {
     let tokenPrice1 = await this.state.saleContract.tokenPrice();
     let tokensSold1 = await this.state.saleContract.tokensSold();
     let saleStart1 = await this.state.saleContract.salestart();
     let saleEnd1 = await this.state.saleContract.saleend();
     let saleDuration1 = await this.state.saleContract.saleduration();
     let saleStartAvailable1 = await this.state.saleContract.saleStartAvailable();
     let saleCurrentAvailable1 = await this.state.saleContract.saleCurrentAvailable();
     let minPurchase1 = await this.state.saleContract.minPurchase();
     let maxPurchase1 = await this.state.saleContract.maxPurchase();
      
     
     let tokenBalanceSold1 =  await this.state.saleContract.tokenbalanceSold();
       
     return ({tokenPrice1,tokensSold1, saleStart1, saleEnd1, saleDuration1, saleStartAvailable1,
      saleCurrentAvailable1,minPurchase1,maxPurchase1, tokenBalanceSold1})
   }
   
  const startSale = async ( ) => {
    this.setState({loading : true });
    try {
        const contractbalance  = await  this.state.saleContract.getcontractbalance({from: this.state.address});
       if (!(this.state.saleDuration > 0 ) || !(this.state.tokenPrice > 0) || !(this.state.saleAvailable > 0) || !(this.state.minPurchase > 0) || !(this.state.maxPurchase > 0)) return
        const saleduration2 = this.state.saleDuration;
        const tokenprice2 =   this.state.tokenPrice * 10 **  18;
       
        const saleAvailable2 = this.state.saleAvailable;
        let approvalvalue = ethers.utils.parseEther(this.state.saleAvailable.toString())
        const minPurchase2 =  this.state.minPurchase;
        const maxPurchase2 =  this.state.maxPurchase;
        
        const signer = this.state.provider.getSigner(this.state.address);
    
        const txitem1 = await this.state.tokenContract.
        connect(signer). 
        approve(this.state.saleContract.address,approvalvalue.toString(), {from: this.state.address});
      
        const receipt1 = await txitem1.wait();
        if (receipt1.status === 0) {
         alert("Approval failed. Please check your balance and try again");
         console.log("Transaction failed");
         throw new Error("Transaction failed");
        }   
        
      //const userapp = await this.state.tokenContracts[this.state.tokensItem.tickertext].allowance(this.state.address,this.state.saleContract.address )
        
        let transaction = await this.state.saleContract.connect(signer).start(   saleduration2.toString(), tokenprice2.toString(), 
              saleAvailable2.toString(), minPurchase2.toString(),  maxPurchase2.toString(),{from: this.state.address});
        let tx = await transaction.wait();
        await this.setState({ loading: false });
    }  catch (error) {
      console.error(error);
      await this.setState({ loading: false });
    }
      
  // window.location.reload(); 
  }
   
   const endSale = async () => {
    this.setState({loading : true });
    try {
       const signer = this.state.provider.getSigner(this.state.address);
      const adminadd = this.state.address;
      let transaction = await this.state.saleContract.connect(signer).endSale({from: adminadd });
    
      let tx = await transaction.wait();
      this.setState({loading : false });
    }  catch (error) {
      console.error(error);
      await this.setState({ loading: false });
    }
     //  window.location.reload(); 
   }

  const endSaleRelease = async () => {
      this.setState({ loading: true });
    try {
      const startsalequantity = await this.state.saleContract.saleStartAvailable();
      const currentsalequantity = await this.state.saleContract.saleCurrentAvailable();
      const nettsales = startsalequantity - currentsalequantity;

    
      const signer = this.state.provider.getSigner(this.state.address);
    
      await this.state.tokenContract.connect(signer).approve( this.state.saleContract.address,nettsales, {from: this.state.address} )
      
      let transaction = await this.state.saleContract.connect(signer).release( {from: this.state.address});
     
      let tx = await transaction.wait();
        await this.setState({ loading: false });
     }  catch (error) {
      console.error(error);
      await this.setState({ loading: false });
    }
     //  window.location.reload(); 
   }

  const endSaleWithdraw = async (e) => {
    try {
      await this.setState({ loading: true });
      const contractbalance  = await this.state.saleContract.getcontractbalance({from: this.state.address});
      // console.log("contractbalance", contractbalance.toString());
      
      const withdrawval =  contractbalance;
      
      const signer = this.state.provider.getSigner(this.state.address);
    
      let transaction =  await this.state.saleContract.connect(signer).withdraw(this.state.address , withdrawval.toString(), {from: this.state.address});
        
      let tx = await transaction.wait();
       await this.setState({ loading: false });
   }  catch (error) {
      console.error(error);
      await this.setState({ loading: false });
    }
       //  window.location.reload(); 
   }

  //   const listenToSell = async token => {
  // //  const transferIds = new Set();
  //   let filter = this.state.saleContract.filters.Sell();
  //   const listener8 = this.state.saleContract.on(filter, ( masterId, itemId, investor,  quantity, tokenPrice,  timecreated,  saletime) => {  
        
  //     // console.log(investor);
  //     // console.log(quantity);
  //     if (investor.toLowerCase() === this.state.address.toLowerCase()) {
  //       console.log("purchase detected");
  //       console.log(quantity.toString());
  //       // if (Number(quantity.toString()) > 0) {
  //       //   window.alert(`Purchase received: ${quantity.toString()} TIGGERPUP ${investor}`)
  //       // }
        
  //     }
  //    });
  //   this.setState({listener8: listener8});
  // }
 
 public connectmm = async () => {
  await this.setState({wcmode: false});
  this.connect();
 }

 public connectwc = async () => {
  await this.setState({wcmode: true});
  this.connect();
 }

  public connect = async () => {
    this.setState({loading: true});
    // bridge url
    let seladdress = '';

    if (this.state.wcmode) {
      const bridge = "https://bridge.walletconnect.org";

      // create new connector
      const connector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal });

      await this.setState({ connector });

      // check if already connected
      if (!connector.connected) {
        // create new session
        await connector.createSession();
        await this.setState({ connector });
      }
       // subscribe to events
      await this.subscribeToEvents();
      //const rpcUrl = getChainData(1337).rpc_url;
      const rpcUrl = getChainData(56).rpc_url;
      const provider = new providers.JsonRpcProvider(rpcUrl);
      await this.setState({provider});
    } else {
      const web3Modal = new Web3Modal({
        network: "localhost",
        cacheProvider: true 
      });
      try {
        const connection = await web3Modal.connect();
        const [selectedAddress] =  await window.ethereum.request({ method: 'eth_requestAccounts' });
        seladdress= selectedAddress.toLowerCase();
        const provider = new ethers.providers.Web3Provider(connection)    
        await this.setState({provider});
        await this.setState({address: seladdress});
      } catch  (error) {
       window.alert(error);
       this.setState({loading: false});
       return;
      }
    }
    await this.setState({loading: false});
}

  public connect2 = async () => {
    await this.setState({loading: true});
    // console.log(seladdress)
     this.setState({saleready: true});
    const TokenContractAddress = tokenAddress.TiggerPup;
    
    const tokencontract = await new ethers.Contract(TokenContractAddress, TiggerPup.abi, this.state.provider )
    const SaleContractAddress = contractAddress.TokenSale;
    const salecontract = await new ethers.Contract(SaleContractAddress, TokenSale.abi, this.state.provider )
    await this.setState({saleContract: salecontract});
    await this.setState({tokenContract: tokencontract});

    const viptokencontract = await new ethers.Contract(this.state.tiggerpupVIPAddress, TiggerPupVIP.abi, this.state.provider )
    await this.setState({viptokenContract: viptokencontract});

     let vipuserbalance = await this.state.viptokenContract.balanceOf(this.state.address);
     vipuserbalance = vipuserbalance * 10 ** -18;
     await this.setState({VipUserBalance: vipuserbalance});
    

    let owneradd  =  await this.state.saleContract.owner({from: this.state.address});

    let isadmin  =  await this.checkisAdmin();
    await this.setState({isAdmin: isadmin});
      
   
    //   // subscribe to events
     await this.subscribeToEvents();
    
   
      let userbalance = await this.state.tokenContract.balanceOf(this.state.address);
      userbalance = userbalance * 10 ** -18;
      await this.setState({userBalance: userbalance});
    
      let walletaddress = await this.state.saleContract.walletAddress();
      await this.setState({walletAddress: walletaddress});
   
      const balance = await this.state.provider.getBalance(walletaddress) ;
      const balanceInBNB = ethers.utils.formatEther(balance);
         // console.log(`balance: ${balanceInBNB} BNB`)
      await this.setState({walletBalance: balanceInBNB});
       
      const mybalance = await this.state.provider.getBalance(this.state.address) ;
      const mybalanceInBNB = ethers.utils.formatEther(mybalance);
         // console.log(`balance: ${balanceInBNB} BNB`)
      await this.setState({mywalletBalance: mybalanceInBNB});
     

      let tiggeraddress = await this.state.saleContract.tickerAddress();
      await this.setState({tiggerAddress: tiggeraddress});


     let salescontractbalance = await this.state.saleContract.getcontractbalance({from: this.state.address});
     salescontractbalance = salescontractbalance * 10 ** -18;
     await this.setState({salesContractBalance: salescontractbalance} );
  
      // listenToStartSale("TIGGERPUP");
      // listenToEndSale("TIGGERPUP");
      // listenToTransfers("TIGGERPUP");
      // this.listenToSell("TIGGERPUP")

        let saleactive = await this.checksaleactive();
        await this.setState({isSaleActive: saleactive});
      
       
        let salesdetails = await this.checkSaleStatus();
       
        if (saleactive) {
         this.setState({tokenPrice : ((salesdetails.tokenPrice1 * 10 ** -18).toString())});; 
         this.setState({tokensSold: (salesdetails.tokensSold1.toString())});;

         this.setState({saleStart: (this.converttimestamp(salesdetails.saleStart1))});; 
         this.setState({saleEnd: (this.converttimestamp(salesdetails.saleEnd1))});; 
      
         //console.log(salesdetails.saleEnd1.toString());
           
         const now =  Math.floor((new Date()).getTime() / 1000);
        //   console.log(now); 
          // console.log(salesdetails.saleEnd1.toString()); 
          if (now > Number(salesdetails.saleEnd1.toString())) {
            await this.setState({isSaleActive: false});
          }

         this.setState({saleDuration : (this.convertduration(salesdetails.saleDuration1))}); 
         this.setState({saleStartAvailable : (salesdetails.saleStartAvailable1.toString())}); 
         this.setState({saleCurrentAvailable:  (salesdetails.saleCurrentAvailable1.toString())}); 
         this.setState({minPurchase:  (salesdetails.minPurchase1.toString())}); 
         this.setState({maxPurchase: (salesdetails.maxPurchase1.toString())}); 

        }
          
        this.setState({tokenBalanceSold: (salesdetails.tokenBalanceSold1.toString())});

        // if (isadmin) {
        //   this.getsalesdetails();
        //   this.getsalesmaster();
        // }
   
    
     

    await this.setState({loading: false});
   
    
  };
  public subscribeToEvents = () => {
    const { connector } = this.state;

    if (!connector) {
      return;
    }

    connector.on("session_update", async (error, payload) => {
      console.log(`connector.on("session_update")`);

      if (error) {
        throw error;
      }

      const { chainId, accounts } = payload.params[0];

      this.onSessionUpdate(accounts, chainId);
    });

    connector.on("connect", (error, payload) => {
      // console.log(`connector.on("connect")`);

      if (error) {
        throw error;
      }

      this.onConnect(payload);
    });

    connector.on("disconnect", (error, payload) => {
      // console.log(`connector.on("disconnect")`);

      if (error) {
        throw error;
      }

      this.onDisconnect();
    });

    if (connector.connected) {
      const { chainId, accounts } = connector;
      const address = accounts[0];
      this.setState({
        connected: true,
        chainId,
        accounts,
        address,
      });
      this.onSessionUpdate(accounts, chainId);
    }

    this.setState({ connector });
  };

  public killSession = async () => {
    const { connector } = this.state;
    if (connector) {
      connector.killSession();
    }
    this.resetApp();
  };

  public resetApp = async () => {
    await this.setState({ ...INITIAL_STATE });

    // listener.unsubscribe();
    // listener2.unsubscribe();
    // listener3.unsubscribe();
    // listener4.unsubscribe();
    //this.state.listener8.unsubscribe();
 
  };

  public onConnect = async (payload: IInternalEvent) => {
    const { chainId, accounts } = payload.params[0];
    const address = accounts[0];
    await this.setState({
      connected: true,
      chainId,
      accounts,
      address,
    });
  //  this.getAccountAssets();


  };

  public onDisconnect = async () => {
    this.resetApp();
  };

  public onSessionUpdate = async (accounts: string[], chainId: number) => {
    const address = accounts[0];
    await this.setState({ chainId, accounts, address });

    await this.getAccountAssets();
  };

  public getAccountAssets = async () => {

    const { address, chainId } = this.state;

    this.setState({ fetching: true });
    try {
      // get account balances
      //const assets = await apiGetAccountAssets(address, chainId);
      const assets = {}
      await this.setState({ fetching: false, address, assets });
    } catch (error) {
     // console.error(error);
      await this.setState({ fetching: false });
    }
  };

 const onGetSalesMaster = async (e) => {
      this.setState({ loading: true });
      try {
        e.preventDefault();
        this.getsalesmaster();
        await this.setState({ loading: false });
      } catch (error) {
        console.error(error);
        await this.setState({ loading: false });
      }
  }

  const onGetSalesDetails = async  (e) => {
      e.preventDefault();
      this.setState({ loading: true });
      try {
       this.getsalesdetails();
       await this.setState({ loading: false });
      } catch (error) {
        console.error(error);
        await this.setState({ loading: false });
      }
  }

   
  const onSubmitNewSale = (e) => {
    e.preventDefault();
     this.startSale();
//    this.startSale(saleDuration, tokenPrice, saleAvailable, minPurchase, maxPurchase);
  }

  const onSubmitEndSale = (e) => {
    e.preventDefault();
    this.endSale();
  }

  const onSubmitRelease = (e) => {
    e.preventDefault();
    this.endSaleRelease();
  }

  const onSubmitWithdraw = (e) => {
    e.preventDefault();
    this.endSaleWithdraw();
  }

  

   

  const onSubmitPurchase = async (e) => {
    e.preventDefault();
    let retval =  await this.checkInvestment();
    if (retval) {
      this.purchase();
    }
    
  }

  const checkInvestment =   async () => {
   const investment = this.state.investment;
    const tokenPrice = this.state.tokenPrice;
    const minPurchase = this.state.minPurchase;
    const maxPurchase = this.state.maxPurchase;
    if (!(investment > 0)) {
      await this.setState({investmentmsg: "Please enter investment amount"});
      return false;
    }
    // if (investment  % tokenPrice  === 0) {
      await this.setState({investmentmsg: ""});
      const purchaseqty = investment  / tokenPrice  ;
      
      //check max
      if (investment > tokenPrice * maxPurchase) {
        await this.setState({investmentmsg: "Quantity exceeds maximum"});
        return false;
      }
      //check min
      if (investment < tokenPrice * minPurchase) {
        await this.setState({investmentmsg: "Quantity is less than minimum"});
        return false;
      }
     // if (purchaseqty >= minPurchase && (purchaseqty <= maxPurchase)) {
        await this.setState({investmentmsg: ""});
        return true;
    //  }
    // } else {
      // await this.setState({investmentmsg: "Amount must be a multiple of token price"})
      // return false;
    // }
  }

  const updateInvestment = async (e) => {
    const investment = e.target.value;
    const tokenPrice = this.state.tokenPrice;
    
    if (!(investment > 0)) {
      console.log(investment)
      return;
    }
   
    const purchaseqty = Number(investment  / tokenPrice ) ;
     
    
    await this.setState({investment : investment });
    await this.setState({purchaseqty : purchaseqty });
  
  }
   

 const downloadFile = ({ data, fileName, fileType }) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType })
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement('a')
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}

const exportToJson = e => {
  e.preventDefault()
  this.downloadFile({
    data: JSON.stringify(this.state.salesDetails),
    fileName: 'salesDetails.json',
    fileType: 'text/json',
  })
}

const exportToCSV = e => {
  e.preventDefault()
  let contents = [];
  contents.push([ "masterId", "itemId" , "investor", "tokenPrice",  "quantity", "timecreated"])  
  this.state.salesDetails.forEach(row => {
    contents.push([row.masterId.toString(),row.itemId.toString(), row.investor, row.tokenPrice * 10 ** -18, row.quantity.toString(), row.timecreated.toString()])          
  });
  let output =  this.makeCSV(contents);
  this.downloadFile({
    data: output,
    fileName: 'salesDetails.csv',
    fileType: 'text/csv',
  })
}



const  makeCSV = (content) => {
    let csv = '';
    content.forEach(value => {
      value.forEach((item, i) => {
        let innerValue = item === null ? '' : item.toString();
        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) {
          result = '"' + result + '"'
        }
        if (i > 0) {csv += ','}
        csv += result;
      })
      csv += '\n';
    })
    return csv
  }

 public purchase = async () => {
  console.log(this.state.wcmode)
  if (this.state.wcmode){
    this.purchasewc();
  } else {
     this.purchasemm();
  }
 }

 public purchasewc = async () => {
    
    const { connector, address, chainId } = this.state;

    if (!connector) {
      return;
    }

    // from
    const from = address;

    // to
    const to = contractAddress.TokenSale;

    // gasPrice
    const gasPrices = await apiGetGasPrices();
    const _gasPrice = gasPrices.slow.price;
    const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)));

    // gasLimit
    const _gasLimit = 210000;
    const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));

    // value
    const amount= this.state.investment;
    const amount2 = amount * 10 ** 18; 
    // const _value = this.state.investment * 10 ** 18;
    const value = sanitizeHex(convertStringToHex(amount2));

     
    let ABI = [ "function purchase()" ];
    let iface = new ethers.utils.Interface(ABI);
    let data = iface.encodeFunctionData("purchase")
      
//      id: 56,
      const customRequest = {
      id: 56,
      jsonrpc: "2.0",
      method: "eth_signTransaction",
      params: [
        {
          from: from,
          to: to,
          data: data,
          value: value,
          gasLimit: 1000000
        },
      ],
    };
  
   
    try {
      // open modal
      this.toggleModal();

      // toggle pending request indicator
      this.setState({ pendingRequest: true });

      // send transaction
      const result = await connector.sendCustomRequest(customRequest);

      // format displayed result
      const formattedResult = {
        method: "Thank you and congrats. You are now a Tigger VIP. Tigger tokens will be airdropped shortly.",
        value: `${value} BNB`,
      };

      // display result
      this.setState({
        connector,
        pendingRequest: false,
        result: formattedResult || null,
      });
    } catch (error) {
      console.error(error);
      window.alert("Purchase did not get processed.")
      this.setState({ connector, pendingRequest: false, result: null });
    }
     window.location.reload();
  };

// gasLimit: 2100000,
  //gasPrice: 8000000000,

  const purchasemm = async ( ) => {
    this.setState({loading : true });
    let amount= this.state.investment;
    const amount2 = amount * 10 ** 18; 
    
    try {
      const signer = this.state.provider.getSigner(this.state.address.toLowerCase());
      const signerbal = await signer.getBalance();
      await this.state.provider.getGasPrice(); // 5000000000

      const transaction = await this.state.saleContract.connect(signer).purchase(  {   value:  amount2.toString() });
      
      let tx = await transaction.wait();
      this.setState({loading : false });
    }  catch (error) {
      console.error(error);
      await this.setState({ loading: false });
    }
   window.location.reload(); 
  }

  public toggleModal = () => this.setState({ showModal: !this.state.showModal });
 
  public render = () => {
    const {
      assets,
      address,
      connected,
      chainId,
      fetching,
      showModal,
      pendingRequest,
      result,
    } = this.state;

 
  

  return (
        <SLayout>
        <Column maxWidth={1000} spanHeight>
          <Banner/>
        </Column>
         </SLayout>
    )
  
    
     
  };
}

export default App;
