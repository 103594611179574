import * as React from "react";
import styled from "styled-components";
//import banner from "../assets/CirclePaw.png";
import banner from "../assets/presalecomplete.jpg";

const SBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const SBanner = styled.div`
  margin-top: 50px;
  margin-right: 50px;
  width: 550px;
  height: 600px;
  background: url(${banner}) no-repeat;
  background-size: cover;
  background-position: center;
`;

const Banner = () => (
  <SBannerWrapper>
    <SBanner />
  </SBannerWrapper>
);

export default Banner;
